<template>
    <button type="button" class="btn w-100 bg-danger text-white" @click="clear">حذف فیلتر ستون‌ها</button>
</template>

<script>
export default {
  name: "clearSortsBtn",
  methods:{
    clear(){
      this.$emit('clear')
    }
  }
}
</script>

<style scoped>

</style>
