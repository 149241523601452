import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllProductCategory extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ProductCategory/GetAllForAdmin')
    }
    setParams(data) {
        super.setRequestParam(data)
    }
}
class GetAllProductCategoryForAll extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`ProductCategory/GetAll`);
    }

}
class DeleteProductCategory extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`ProductCategory/Delete?id=${data.id}`);
    }
}

class UpdateProductCategory extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ProductCategory/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateProductCategory extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ProductCategory/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

export {
    GetAllProductCategory,
    GetAllProductCategoryForAll,
    DeleteProductCategory,
    UpdateProductCategory,
    CreateProductCategory
};
