import WebServiceRequest from '../Api/WebServiceRequest'

class DeleteOption extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`Option/Delete?id=${data.id}`);
    }
}

class UpdateOption extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Option/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateOption extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Option/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class GetAllOptions extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`Option/GetAll`)
    }

}
class DeleteOptionValue extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`OptionValue/Delete?id=${data.id}`);
    }
}

class UpdateOptionValue extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('OptionValue/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateOptionValue extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('OptionValue/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class GetAllOptionValuesByOptionId extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`OptionValue/GetValuesByOptionIdNew?optionId=${data.optionId}`)
    }
}



export {
    GetAllOptions,
    CreateOption,
    UpdateOption,
    DeleteOption,
    GetAllOptionValuesByOptionId,
    CreateOptionValue,
    UpdateOptionValue,
    DeleteOptionValue,
};
