import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllProperties extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`CategoryProperty/GetAll?pageNumber=${data.pageNumber}&count=${data.count}&searchCommand=${data.searchCommand}`);
    }
}

class DeleteProperty extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`CategoryProperty/Delete?id=${data.id}`);
    }
}
class GetPropertiesByCategoryId extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`CategoryProperty/GetByCategoryId?categoryId=${data.categoryId}`);
    }
}

class UpdateProperty extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('CategoryProperty/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateProperty extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('CategoryProperty/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

export {
    GetAllProperties,
    DeleteProperty,
    UpdateProperty,
    GetPropertiesByCategoryId,
    CreateProperty
};
